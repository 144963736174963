import { assert } from '@chainflip/utils/assertion';
import { type ChainflipAsset, type ChainflipChain } from '@chainflip/utils/chainflip';
import {
  backspinEthereum,
  bitcoin,
  bitcoinTestnet,
  type ChainData,
  type ChainId,
  dotTestnet,
  ethereum,
  polkadot,
  sepolia,
  arbitrum,
  backspinArbitrum,
  arbitrumSepolia,
  backspinSolana,
  solana,
  solanaDevnet,
} from '@/shared/assets/chains';
import {
  beth$,
  bflip$,
  btc$,
  busdc$,
  busdt$,
  type ChainflipToken,
  dot$,
  eth$,
  flip$,
  pdot$,
  tbtc$,
  usdc$,
  sflip$,
  susdc$,
  seth$,
  usdt$,
  susdt$,
  arbusdc$,
  arbeth$,
  barbeth$,
  barbusdc$,
  sarbeth$,
  sarbusdc$,
  sol$,
  solusdc$,
  tsol$,
  bsol$,
  bsolusdc$,
  tsolusdc$,
} from '../assets/tokens';

const chainflipNetworks = ['unknown', 'backspin', 'sisyphos', 'perseverance', 'mainnet'] as const;

export type ChainflipNetwork = (typeof chainflipNetworks)[number];

function assertChainflipNetwork(network: string): asserts network is ChainflipNetwork {
  assert(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chainflipNetworks.includes(network as any),
    `Invalid network: "${network}"`,
  );
}

export const getChainflipNetwork = (): ChainflipNetwork => {
  // using unknown because this function is bundled in the shared package
  const network = process.env.NEXT_PUBLIC_CHAINFLIP_NETWORK ?? 'unknown';
  assertChainflipNetwork(network);
  return network;
};

export const getEthereumChain = () => {
  const network = getChainflipNetwork();
  if (network === 'backspin') return backspinEthereum;
  if (network === 'mainnet') return ethereum;
  return sepolia;
};

const getArbitrumChain = () => {
  const network = getChainflipNetwork();
  if (network === 'backspin') return backspinArbitrum;
  if (network === 'mainnet') return arbitrum;
  return arbitrumSepolia;
};

const getSolanaChain = () => {
  const network = getChainflipNetwork();
  if (network === 'backspin') return backspinSolana;
  if (network === 'mainnet') return solana;
  return solanaDevnet;
};

export const getChainflipToken = (asset: ChainflipAsset): ChainflipToken => {
  const network = getChainflipNetwork();

  if (network === 'mainnet') {
    return {
      Flip: flip$,
      Usdc: usdc$,
      Usdt: usdt$,
      Eth: eth$,
      Dot: dot$,
      Btc: btc$,
      ArbUsdc: arbusdc$,
      ArbEth: arbeth$,
      Sol: sol$,
      SolUsdc: solusdc$,
    }[asset];
  }

  if (network === 'backspin') {
    return {
      Flip: bflip$,
      Usdc: busdc$,
      Usdt: busdt$,
      Eth: beth$,
      Dot: pdot$,
      Btc: tbtc$,
      ArbUsdc: barbusdc$,
      ArbEth: barbeth$,
      Sol: bsol$,
      SolUsdc: bsolusdc$,
    }[asset];
  }

  return {
    Flip: sflip$,
    Usdc: susdc$,
    Usdt: susdt$,
    Eth: seth$,
    Dot: pdot$,
    Btc: tbtc$,
    ArbUsdc: sarbusdc$,
    ArbEth: sarbeth$,
    Sol: tsol$,
    SolUsdc: tsolusdc$,
  }[asset];
};

export const chainflipChainMap = {
  Bitcoin: getChainflipNetwork() === 'mainnet' ? bitcoin : bitcoinTestnet,
  Ethereum: getEthereumChain(),
  Polkadot: getChainflipNetwork() === 'mainnet' ? polkadot : dotTestnet,
  Arbitrum: getArbitrumChain(),
  Solana: getSolanaChain(),
} as const satisfies Record<ChainflipChain, ChainData>;

export const chainflipAssetMap = {
  Eth: getChainflipToken('Eth'),
  Usdc: getChainflipToken('Usdc'),
  Usdt: getChainflipToken('Usdt'),
  Flip: getChainflipToken('Flip'),
  Dot: getChainflipToken('Dot'),
  Btc: getChainflipToken('Btc'),
  ArbUsdc: getChainflipToken('ArbUsdc'),
  ArbEth: getChainflipToken('ArbEth'),
  Sol: getChainflipToken('Sol'),
  SolUsdc: getChainflipToken('SolUsdc'),
} as const satisfies Record<ChainflipAsset, ChainflipToken>;

export const getChainflipAsset = (
  chainId: ChainId,
  tokenAddress: string,
): ChainflipAsset | undefined =>
  Object.entries(chainflipAssetMap).find(
    ([, token]) => token.chain.id === chainId && token.address === tokenAddress,
  )?.[0] as ChainflipAsset | undefined;

export const FLIP_SYMBOL = chainflipAssetMap.Flip.symbol;
